import React from 'react';
import './App.css';

// Import your background image
import backgroundImage from './thunder_house_background.png';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvlip3SUimYTr0bS8G3KOCQGCa8a6aJtk",
  authDomain: "courtesy-567e4.firebaseapp.com",
  databaseURL: "https://courtesy-567e4-default-rtdb.firebaseio.com",
  projectId: "courtesy-567e4",
  storageBucket: "courtesy-567e4.appspot.com",
  messagingSenderId: "887277508464",
  appId: "1:887277508464:web:70f667adf7df43f5aaa1b5",
  measurementId: "G-FB25EX4FC9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh', // Ensure the background covers the full page height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const titleStyle = {
    color: 'white',
    fontSize: '32px',
    paddingTop: '20px', // Add some top padding to push the title to the top
  };

  return (
    <div style={backgroundStyle}>
      {/* Your title */}
      <div style={titleStyle}>
        Thunder House
      </div>
    </div>
  );
}

export default App;
